@import '../../../Common/Colors/colors.less';

.light {
  color: @white;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.error {
  color: @red;
}

@primary-color: #6456f5;@border-radius-base: 4px;