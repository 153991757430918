@import '../../../Common/Colors/common.less';
@import '../../../Common/Sizes/metrics.less';

.less-opacity {
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}

.app-container {
  transition: @transition-base;

  &:hover .platform-icon-container {
    box-shadow: @box-shadow-base;
  }

  &:hover .badge-container {
    visibility: visible;
  }
}

.badge-container {
  @badge-size: @metrics-normal * 3;

  height: @badge-size;
  width: @badge-size;
  border-radius: calc(@badge-size / 2);
  background-color: @red;
  visibility: hidden;
  transition: @transition-base;
}

.platform-icon-container {
  height: @metrics-x-large;
  width: @metrics-x-large;
  border: @border-base;
  transition: @transition-base;
}

@primary-color: #6456f5;@border-radius-base: 4px;