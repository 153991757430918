@import '../../../Common/Colors/colors.less';
@import '../../../Common/Colors/common.less';

.client-card-container {
  background-color: lighten(@grey, 5%);
  border: @border-base;
  transition: box-shadow 0.3s, border-color 0.3s;

  &:hover {
    border-color: transparent;
    box-shadow: @box-shadow-base;
  }
}

@primary-color: #6456f5;@border-radius-base: 4px;