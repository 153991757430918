@import '../../../Common/Colors/colors.less';

.icon-container {
  transition: color 0.3s ease-in-out;
  color: lighten(@black, 70%);

  &:hover {
    color: @primary-color;
  }
}

.danger {
  &:hover {
    color: @red;
  }
}

@primary-color: #6456f5;@border-radius-base: 4px;