@import '../../Common/Sizes/metrics.less';
@import '../../Common/Colors/colors.less';

.carousel-container {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: @black-backdrop-light;
  }
}

.carousel-quote {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: @black-backdrop-light;
  border-radius: 4px;
}

.signin-sider-container {
  background-color: @white;
  box-shadow: -2px 0px 15px 2px rgba(255, 255, 255, 0.8);
}

.title {
  color: @primary-color;
}

@primary-color: #6456f5;@border-radius-base: 4px;