@import '../../../Common/Colors/colors.less';

.loading-container {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: @black-backdrop;
  z-index: 9999;
}

@primary-color: #6456f5;@border-radius-base: 4px;