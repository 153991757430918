@import '../../Common/Colors/colors.less';
@import '../../Common/Colors/common.less';
@import '../../Common/Sizes/metrics.less';

.dashboard-header {
  background-color: @grey;
}

.ant-layout-sider-trigger {
  background-color: lighten(@grey, 2%);
  color: @black;
}

.dashboard-sider-container {
  background-color: @grey;
}

.dashboard-content-header {
  background-color: transparent;
  height: auto;
}

.content-container {
  overflow-y: auto;
}

.content-sub-container {
  background-color: @white;
}

.back-top-container {
  right: @metrics-large;
  bottom: @metrics-large;
}

.back-top {
  height: calc(@metrics-small * 10);
  width: calc(@metrics-small * 10);
  border-radius: calc(@metrics-smaller * 10);
  color: @white;
  background-color: @black-backdrop;
  transition: @transition-base;

  &:hover {
    background-color: @primary-color;
  }
}

@primary-color: #6456f5;@border-radius-base: 4px;