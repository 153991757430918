@import '../../../Common/Colors/colors.less';

.rowClass {
  @backgroundColor: lighten(@grey, 5%);

  &:hover > td {
    background-color: lighten(@primary-color-low, 30%) !important;
  }

  &:nth-child(odd) {
    background-color: lighten(@grey, 5%);
  }

  & > td.ant-table-column-sort {
    background-color: @backgroundColor;
  }
}

@primary-color: #6456f5;@border-radius-base: 4px;