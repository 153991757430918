@import '../../../Common/Colors/colors.less';
@import '../../../Common/Sizes/metrics.less';

.release-card-container {
  background-color: lighten(@grey, 2%);
  border: @metrics-smaller solid @grey;
}

.release-divider {
  color: darken(@grey, 10%);
}

@primary-color: #6456f5;@border-radius-base: 4px;