@import '../../../../Common/Sizes/metrics.less';
@import '../../../../Common/Colors/common.less';

.avatar {
  @avatar-size: calc(@metrics-small * 10);
  height: @avatar-size;
  width: @avatar-size;
  border-radius: calc(@avatar-size / 2);
  transition: @transition-base;
  border: @border-base;

  &:hover {
    box-shadow: @box-shadow-base;
  }
}

@primary-color: #6456f5;@border-radius-base: 4px;